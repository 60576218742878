<template>
  <div class="wrap">
    <div class="filter-wrap">
      <div class="filter" v-if="dateFilter">
        <p class="text-label mr-3">{{ dateFilter.label }}</p>
        <div class="date-picker-wrap pr-6">
          <v-menu
            v-model="dateFilter.startPicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            absolute
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateFilter.startDate"
                label="시작일"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                class="date-text"
                color="black"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFilter.startDate"
              @input="dateFilter.startPicker = false"
              @change="dateValid()"
            ></v-date-picker>
          </v-menu>
          <p class="mr-2 ml-2 mb-7">~</p>
          <v-menu
            v-model="dateFilter.endPicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            absolute
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateFilter.endDate"
                label="종료일"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                class="date-text"
                color="black"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFilter.endDate"
              @input="dateFilter.endPicker = false"
              @change="dateValid()"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="filter" v-for="radio in radioFilters" :key="radio.key">
        <p class="text-label mr-3">{{ radio.name }}</p>
        <v-radio-group
          @change="updateRadio($event, radio.key)"
          dense
          row
          :value="radio.value"
        >
          <v-radio
            v-for="row in radio.data"
            :key="row.value"
            :label="row.name"
            :value="row.value"
            class="mr-4"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="filter" v-for="select in selectFilters" :key="select.key">
        <p class="text-label mr-3">{{ select.name }}</p>
        <v-select
          class="select-box"
          outlined
          dense
          :items="select.data"
          :value="select.value"
          item-text="name"
          @change="updateSelect($event, select.key)"
        ></v-select>
      </div>
    </div>
    <v-text-field
      v-if="showTextField"
      @input="type === 'server'?'':updateSearch()"
      @keydown="type === 'server' && $event.key === 'Enter' ? updateSearch() : ''"
      append-icon="mdi-magnify"
      :label="searchPlaceHolder"
      v-model="searchString"
      hide-details
      dense
      outlined
      color="black"
      class="table-search mb-2"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import time from '@/util/time';

export default {
  name: 'SearchFilter',
  props: {
    type: {
      type: String,
    },
    dateFilter: {
      type: Object,
    },
    radioFilters: {
      type: Object,
    },
    selectFilters: {
      typr: Object,
    },
    search: {
      type: String,
    },
    searchPlaceHolder: {
      type: String,
    },
    showTextField: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  data: () => ({
    searchString: '',
  }),
  methods: {
    ...mapActions({
      alert: 'dialog/alert',
    }),
    updateRadio(e, key) {
      this.$emit('updateRadio', e, key);
    },
    updateSelect(e, key) {
      this.$emit('updateSelect', e, key);
    },
    updateSearch() {
      this.$emit('updateSearch', this.searchString);
    },
    dateValid() {
      const start = this.dateFilter.startDate;
      const end = time.moment(this.dateFilter.endDate).add('1', 'days');
      if (!time.moment(end).isAfter(start)) {
        this.alert(['info', '종료일은 시작일보다 커야합니다.']);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .table-search {
    width:240px;
    flex-grow: 0;
    margin-top:4px;
    flex-shrink: 0;
  }
  .filter-wrap {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    .filter {
      display: flex;
      flex-grow: 0;
      align-items: center;
      width: max-content;
      margin-right: 20px;
      .date-picker-wrap {
        display: flex;
        align-items: center;
        margin-bottom:-24px;
      }
      .select-box {
        margin-bottom: -20px;
        width: 240px;
      }
    }
  }
}
.date-text{
  width:110px;
}
</style>
